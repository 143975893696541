
import SO_LOGO from "../../assets/images/so-logo.png";
import "./header.scss";
import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import NavDropdown from 'react-bootstrap/NavDropdown';
import config from "../../config";
import { useEffect, useState } from "react";
import UtilityService from "../../utils/utility";
import GAConfig from "../../ga.config";
import { useLocation, useSearchParams } from "react-router-dom";

const HeaderComponent = () => {
    const location = useLocation();
    const [searchParams, setSearchParams] = useSearchParams();
    const [toggle, setToggle] = useState(false);
    const [isHeader, setIsHeader] = useState(true);

    useEffect(() => {
        try {
            if (location.pathname.includes("/techsauce")) {
                UtilityService().clickSendEvent(GAConfig.event.load, "Load header from " + "techsauce");
                setIsHeader(false);
            } 
        } catch {

        }

    }, [])

    const ToggleMenu = () => {
        if (toggle) {
            setToggle(false);
            document.body.style.overflow = 'auto';
        } else {
            setToggle(true);
            document.body.style.overflow = 'hidden';
        }

    }

    const clickMenu = (eventValue: string) => {
        UtilityService().clickSendEvent(GAConfig.event.click, eventValue);
    }
    return (
        <>
            {
                isHeader &&
                <div className="header">

                    <Navbar expand="md">
                        <div className="container-control">
                            <Navbar.Brand href="/">
                                <img src={SO_LOGO} className="logo-so"></img>
                            </Navbar.Brand>
                            <Navbar.Toggle aria-controls="basic-navbar-nav" onClick={ToggleMenu} />
                            <Navbar.Collapse id="basic-navbar-nav">
                                <Nav className="ml-auto">
                                    <Nav.Link href="/" onClick={() => clickMenu('หน้าหลัก')}>หน้าหลัก</Nav.Link>
                                    <Nav.Link href={config.app.resume_builder} target="_blank" onClick={() => clickMenu('Resume Builder')}>สร้าง Resume ฟรี</Nav.Link>
                                    <Nav.Link href={config.app.jobhack} target="_blank" onClick={() => clickMenu('หางานสาย Tech')}>หางานสาย Tech</Nav.Link>
                                    <Nav.Link href={config.app.interview_ai} target="_blank" onClick={() => clickMenu('ทดลองสัมภาษณ์งาน')}>ทดลองสัมภาษณ์งาน ฟรี!</Nav.Link>
                                    <Nav.Link href={config.app.personality} target="_blank" onClick={() => clickMenu('ทดสอบบุคลิกภาพ')}>ทดสอบบุคลิกภาพ</Nav.Link>
                                    <Nav.Link href={config.app.jobhack_article} target="_blank" onClick={() => clickMenu('บทความ')}>บทความ</Nav.Link>
                                    <Nav.Link href={config.app.about_so} target="_blank" onClick={() => clickMenu('เกี่ยวกับเรา')}>เกี่ยวกับเรา</Nav.Link>
                                    {/* <NavDropdown title="Dropdown" id="basic-nav-dropdown">
                                    <NavDropdown.Item href="#action/3.1">Action</NavDropdown.Item>
                                    <NavDropdown.Item href="#action/3.2">
                                        Another action
                                    </NavDropdown.Item>
                                    <NavDropdown.Item href="#action/3.3">Something</NavDropdown.Item>
                                    <NavDropdown.Divider />
                                    <NavDropdown.Item href="#action/3.4">
                                        Separated link
                                    </NavDropdown.Item>
                                </NavDropdown> */}
                                </Nav>
                            </Navbar.Collapse>
                        </div>
                    </Navbar>


                </div>
            }
        </>
    )
}

export default HeaderComponent;