const GAConfig = {
    event: {
        click: "click_event",
        load: "load_event",
        error: "error_event"
    },
    parameters: {
        action_on: "param_action_on",
        value: "param_value",
    }

}

export default GAConfig;