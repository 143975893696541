import config from "../config"
import PostInputModel from "../model/post-input.model"
import { initializeApp } from "firebase/app";
import { addDoc, collection, doc, getDoc, getDocs, getFirestore, orderBy, query, setDoc, Timestamp, updateDoc, where } from "firebase/firestore";

export default function ApiServices() {

    return {
        async getInputValue() {
            return fetch(config.api.base_url + config.api.getInput, {
                method: 'GET', headers: {
                    'Authorization': 'Basic ' + window.btoa('mang:bat123'),
                }
            }
            ).then(res => res.json()).catch(err => console.log(err))
        },
        async getRecommendJobs(title: string) {
            return fetch(config.api.jobnow_plus + title + "&limit=5", {
                method: 'GET', headers: {
                }
            }
            ).then(res => res.json()).catch(err => console.log(err))
        },
        async getIP() {

            return fetch('https://geolocation-db.com/json/', {
                method: 'GET'
            }).then(res => res.json()).catch(err => console.log(err))
        },
        async postInputValue(objectResult: PostInputModel) {
            return fetch(config.api.base_url + config.api.postInput, {
                method: 'POST', headers: {
                    'Authorization': 'Basic ' + window.btoa('mang:bat123'),
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(objectResult)
            }
            ).then(res => res.json()).catch(err => console.log(err))
        },
        async postVerifyValue(token: string) {
            const objectResult = { secret: config.recaptcha_server_key, response: token }
            return fetch(config.recaptcha_url, {
                method: 'POST', headers: {

                },
                body: JSON.stringify(objectResult)
            }
            ).then(res => res.json()).catch(err => console.log(err))
        },
        async createCustomAnalytic(eventName: any, eventParam: any, product: Object) {
            const app = initializeApp(config.firebase);
            const db = getFirestore(app);
            const docData = {
                created_date: Timestamp.now(),
                event_name: eventName,
                event_value: eventParam,
                product: product
            };
            return await addDoc(collection(db, "salary_analytic"), docData);
        },
        async getReport() {
            const app = initializeApp(config.firebase);
            const db = getFirestore(app);
            const q = query(collection(db, "salary_analytic"));

            const querySnapshot = await getDocs(q);
            return querySnapshot;

        }
        ,
        async getReportResume() {
            const app = initializeApp(config.firebase);
            const db = getFirestore(app);
            const q = query(collection(db, "resume_analytic"));

            const querySnapshot = await getDocs(q);
            return querySnapshot;

        }


    }
}