import Lottie from "lottie-react";
import React, { useState } from "react";
import { useLocation, useNavigate, useSearchParams } from "react-router-dom";
import LoadingAnimation from "../../assets/json/loading.json";
import GAConfig from "../../ga.config";
import PostInputModel from "../../model/post-input.model";
import ResultModel from "../../model/result.model";
import ApiServices from "../../services/api.services";
import UtilityService from "../../utils/utility";
import "./loading.scss";
import { Button, Container } from "react-bootstrap";
import config from "../../config";
const LoadingPage = () => {
    const [searchParams, setSearchParams] = useSearchParams();
    const [objectResult, setObjectResult] = useState<PostInputModel>();
    let navigate = useNavigate();
    const [external, setExternal] = useState("");
    const location = useLocation();
    React.useEffect(() => {
        let externalValue = "";
        try {
            if (location.pathname.includes("/techsauce")) {
                externalValue = "techsauce";
                setExternal("techsauce");
            }
        } catch {

        }
        try {
            if (searchParams.get("result")) {
                // console.log(searchParams.get("result"));
                const getInputModel: PostInputModel = JSON.parse(searchParams.get("result")!);

                getInputModel.current_salary = Number(getInputModel.current_salary);
                getInputModel.experience_value = Number(getInputModel.experience_value);
                console.log(getInputModel);
                // setObjectResult(JSON.parse(searchParams.get("result")!));
                UtilityService().clickSendEvent(GAConfig.event.load, "Ext : " + getInputModel.role_value)
                LoadResult(getInputModel);
            } else if (location.state.inputValue) {
                const objectResult: PostInputModel = location.state.inputValue;

                if (externalValue) {
                    UtilityService().clickSendEvent(GAConfig.event.load, "Int : " + objectResult.role_value, false, external);
                    LoadResult(location.state.inputValue, externalValue);
                } else {
                    UtilityService().clickSendEvent(GAConfig.event.load, "Int : " + objectResult.role_value, false);
                    LoadResult(location.state.inputValue);
                }

            } else {
                navigate('/' + external);
            }

        } catch {

            navigate('/' + external);
        }


    }, [])

    const onClickResumeBuilder = (url:any) => {
        UtilityService().clickSendEvent("Click Resume builder", "Click ads Resume Builder loading");
        window.open(url, "_blank");
    }

    const LoadResult = (objectResult: PostInputModel, external?: string) => {
        ApiServices().postInputValue(objectResult).then((res:ResultModel) => {
            console.log(res);

            if(external){
                navigate("/"+external+"/result", {state:{result: res, inputValue: objectResult }})
            }else{
                navigate("/result", {state:{result: res, inputValue: objectResult }})
            }

        })
    }

    return (
        <>
            <div className="d-flex loading">
                <div className="loading-control">
                    <Lottie animationData={LoadingAnimation} loop={true} className="loading-json" />
                    <span className="loading-text">กำลังตรวจสอบ . . . </span>
                </div>
                <Container>
                    <div className="ads-control">
                        <span className="ads-text">*Tip: <span className="ads-text-regular">การใช้เรซูเม่ในการสมัครงานเป็นสิ่งที่สำคัญ เนื่องจากเรซูเม่ช่วยให้คุณสามารถนำเสนอประสบการณ์ที่คุณมีอย่างชัดเจนและเน้นความสามารถที่เกี่ยวข้องกับตำแหน่งงานนั้น นอกจากนี้เรซูเม่ยังช่วยให้นายจ้างสามารถวิเคราะห์คุณสมบัติและความสามารถของคุณได้อย่างรวดเร็ว ดังนั้น การเตรียมเรซูเม่ให้ดีเป็นสิ่งที่สำคัญเมื่อคุณต้องการสมัครงานใหม่ๆ</span></span>
                        <Button className="ads-button-center" onClick={() => onClickResumeBuilder(config.app.resume_builder)}>เริ่มสร้าง Resume ฟรี คลิก!</Button>
                    </div>
                </Container>
            </div>
        </>
    )
}

export default LoadingPage;