const test = {
    ga_4: "G-6BC2ZJZN37",
    ga: "",
    ga_4_techsauce: "G-VCK1FBMM1J",
    gtm_id: "GTM-KJJDKFG",
    recaptcha_key: "6LfvrvAjAAAAACDZ-XoPlg4czx2sA40uIO2C_gxe",
    recaptcha_server_key: "6LfvrvAjAAAAANvdwwkHOef-9wOXodsSyODYXWjB",
    recaptcha_url: "https://www.google.com/recaptcha/api/siteverify",
    firebase: {
        apiKey: "AIzaSyBZtAANzQO-IsrWT3Iwmj9i0_-5DjwONV8",
        authDomain: "scoutout-mang-prod.firebaseapp.com",
        databaseURL: "https://scoutout-mang-prod.firebaseio.com",
        projectId: "scoutout-mang-prod",
        storageBucket: "scoutout-mang-prod.appspot.com",
        messagingSenderId: "1045861674744",
        appId: "1:1045861674744:web:e3bf4ca8b3b6ecceab950d",
        measurementId: "G-ZB69LTZRJM"
    },
    api: {
        x_api_key: "Basic bWFuZ0BzY291dG91dC5uZXQ6c2NvdXQxMjM0",
        jobnow_plus: "https://plus.jobnow.work/api/jobs/search?q=",
        base_url: "https://asia-northeast1-scoutout-mang-test-e17bf.cloudfunctions.net",
        getInput: "/get_tech_data_mandatory",
        postInput: "/predict_tech_salary"
    },
    app: {
        jobrec_click_url: 'https://plus.jobnow.work/job/',
        jobhack: 'https://jobhack.co?utm_source=SALARY_TECH',
        resume_builder: 'https://resume-builder.jobhack.co?utm_source=SALARY_TECH&utm_medium=header',
        interview_ai: 'https://interview.jobhack.co?utm_source=SALARY_TECH',
        jobhack_article: 'https://jobhack.co/careers?utm_source=SALARY_TECH',
        personality: 'https://quiz.scoutout.co?utm_source=SALARY_TECH',
        about_so: 'https://scoutout.co?utm_source=SALARY_TECH',
        terms: 'https://scoutout.co/terms-th/',
        privacy: 'https://scoutout.co/privacy-th/'
    }
};


const prod = {
    ga_4: "G-60Y6NPHSRZ",
    ga: "",
    ga_4_techsauce: "G-VCK1FBMM1J",
    gtm_id: "GTM-W8DNNJP",
    recaptcha_key: "6Lfs1wIkAAAAAFqYPpdgcAhxHp_EXA9zDs36-b3B",
    recaptcha_server_key: "6LfvrvAjAAAAANvdwwkHOef-9wOXodsSyODYXWjB",
    recaptcha_url: "https://www.google.com/recaptcha/api/siteverify",
    firebase: {
        apiKey: "AIzaSyBZtAANzQO-IsrWT3Iwmj9i0_-5DjwONV8",
        authDomain: "scoutout-mang-prod.firebaseapp.com",
        databaseURL: "https://scoutout-mang-prod.firebaseio.com",
        projectId: "scoutout-mang-prod",
        storageBucket: "scoutout-mang-prod.appspot.com",
        messagingSenderId: "1045861674744",
        appId: "1:1045861674744:web:e3bf4ca8b3b6ecceab950d",
        measurementId: "G-ZB69LTZRJM"
    },
    api: {
        x_api_key: "Basic bWFuZ0BzY291dG91dC5uZXQ6c2NvdXQxMjM0",
        jobnow_plus: "https://plus.jobnow.work/api/jobs/search?q=",
        base_url: "https://asia-northeast1-scoutout-mang-prod.cloudfunctions.net",
        getInput: "/get_tech_data_mandatory",
        postInput: "/predict_tech_salary"
    },
    app: {
        jobrec_click_url: 'https://plus.jobnow.work/job/',
        jobhack: 'https://jobhack.co?utm_source=SALARY_TECH',
        resume_builder: 'https://resume-builder.jobhack.co?utm_source=SALARY_TECH&utm_medium=header',
        interview_ai: 'https://interview.jobhack.co?utm_source=SALARY_TECH',
        jobhack_article: 'https://jobhack.co/careers?utm_source=SALARY_TECH',
        personality: 'https://quiz.scoutout.co?utm_source=SALARY_TECH',
        about_so: 'https://scoutout.co?utm_source=SALARY_TECH',
        terms: 'https://scoutout.co/terms-th/',
        privacy: 'https://scoutout.co/privacy-th/'
    }
};

const config = process.env.REACT_APP_ENVIRONMENT === 'production'
    ? prod
    : test;

export default {
    // Add common config values here
    MAX_ATTACHMENT_SIZE: 5000000,
    ...config
};