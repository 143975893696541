import React, { useState } from "react";
import { Form } from "react-bootstrap";
import { Typeahead } from "react-bootstrap-typeahead";
import { GoogleReCaptcha, GoogleReCaptchaProvider, IWithGoogleReCaptchaProps, useGoogleReCaptcha } from "react-google-recaptcha-v3";
import { useNavigate } from "react-router-dom";
import HeaderComponent from "../../components/header/header";
import config from "../../config";
import InputModels, { Experience, Role } from "../../model/input.model";
import ApiServices from "../../services/api.services";
import "./landing.scss";
import IMG_COVER_LANDING from "../../assets/images/img-landing-cover.png";
import IMG_LANDING_WHY_1 from "../../assets/images/landing-why-1.png";
import IMG_LANDING_WHY_2 from "../../assets/images/landing-why-2.png";
import IMG_LANDING_WHY_3 from "../../assets/images/landing-why-3.png";
import PostInputModel from "../../model/post-input.model";
import Lottie from "lottie-react";
import LoadingAnimation from "../../assets/json/loading.json";
import FooterComponent from "../../components/footer/footer";
import FloatingAdsComponent from "../../modal/floating-ads";
import UtilityService from "../../utils/utility";
import GAConfig from "../../ga.config";
import ReactGA from "react-ga4";
import { useLocation, useSearchParams } from "react-router-dom";
import AnalyticModel from "../../model/analytic.model";
const LandingPage = () => {

    const [inputValues, setInputValues] = useState<InputModels>();
    const [autocompleteList, setAutocompleteList] = useState<string[]>([]);
    const [currentSalary, setCurrentSalary] = useState(0);
    const [jobRole, setJobRole] = useState("");
    const [experience, setExperience] = useState(0);
    const [isLookingJobs, setLookingJobs] = useState(false);
    const [employType, setEmployType] = useState("");
    const [token, setToken] = useState("");
    const [external , setExternal] = useState("");
    const [refreshReCaptcha, setRefreshReCaptcha] = useState(false);
    let navigate = useNavigate();
    const location = useLocation();
    const [isSubmiting, setIsSubmiting] = useState(false);
    React.useEffect(() => {
        ReactGA.send({ hitType: "pageview", page: "/", title: "Landing Page" });
        try {
            let eventValue : AnalyticModel = {
                name: "Landing Page"
            }
            if (location.pathname.includes("/techsauce")) {
                eventValue.channel = "Techsauce";
                setExternal("techsauce");
            } else{
                eventValue.channel = "/";
            }
            ApiServices().getIP().then((res) => {

                if (res.IPv4) {
                    eventValue.ip_address = res.IPv4
                }
                ApiServices().createCustomAnalytic("page_view", eventValue, "salary-checker-tech").then((res) => {
                    console.log(res);
                })
            })
        } catch {
            let eventValue : AnalyticModel = {
                name: "Landing Page",
                channel: "/"
            }
            ApiServices().getIP().then((res) => {

                if (res.IPv4) {
                    eventValue.ip_address = res.IPv4
                }
                ApiServices().createCustomAnalytic("page_view", eventValue, "salary-checker-tech").then((res) => {
                    console.log(res);
                })
            })
        }
        getInputValueApi();

    }, [])

    const getInputValueApi = () => {


        ApiServices().getInputValue().then((res: InputModels) => {
            console.log(res);
            setInputValues(res);
            let listat: string[] = new Array();
            res.roles.map((item: Role, index: number) => {
                listat.push(item.role.toString());
            })

            setAutocompleteList(listat);

        })
    }


    const onVerify = (token: any) => {
        if (token) {
            setToken(token);
        } else {
            console.log("error recaptcha")
        }
    }

    const submit = () => {
        if (token) {
            setIsSubmiting(true);
            if (currentSalary && employType && jobRole && experience) {
                UtilityService().clickSendEvent(GAConfig.event.click, "Submit : " + jobRole + " : " +  currentSalary + " : " + employType, isLookingJobs)
                setRefreshReCaptcha(r => !r);
                const objectResult: PostInputModel = {
                    current_salary: Number(currentSalary),
                    employment_type: employType,
                    role_value: jobRole,
                    experience_value: Number(experience),
                    looking_for_jobs: isLookingJobs
                }

                console.log(objectResult);
                if(external){
                    UtilityService().clickSendEvent(GAConfig.event.click, "Submit : " + jobRole + " : " +  currentSalary + " : " + employType, isLookingJobs, external)
                    navigate("/"+external+"/loading", { state: { inputValue: objectResult } })
                }else{
                    UtilityService().clickSendEvent(GAConfig.event.click, "Submit : " + jobRole + " : " +  currentSalary + " : " + employType, isLookingJobs)
                    navigate("/loading", { state: { inputValue: objectResult } })
                }
                
            }


        }


    }



    const handleExpChange = (event: any) => {
        console.log("EXP : " + event.target.value);
        setExperience(event.target.value)
    }

    const handleJobRoleChange = (event: any) => {
        console.log(event[0]);
        if(inputValues){
            inputValues.roles.map((item: Role, index: number) => {
                if(item.role === event[0]){
                    setJobRole(item.value);
                }
            })
        }
       

        
    }

    const handleSalaryChange = (event: any) => {
        setCurrentSalary(event.target.value)
    }

    const handleLookingJobs = (event: any) => {
        setLookingJobs(event.target.checked);
    }

    const handleEmployTypeChange = (event: any) => {
        setEmployType(event.target.value)
    }

    return (
        <>
            <div className="landing d-flex flex-column">
                <HeaderComponent></HeaderComponent>
                <div className="section-top-control">
                    <div className="container grid-control">
                        <div className="left-control">

                            <div className="text-control">
                                <img src={IMG_COVER_LANDING} className="img-headline"></img>
                                <h1 className="text-headline">เช็กอัตราเงินเดือน</h1>
                                <span className="text-subheadline">เทียบกับความสามารถ และ ประสบการณ์ของคุณ ได้ฟรี!</span>
                                <span className="text-descheadling">คนทำงานสาย IT อยากสมัครงาน ย้ายงาน
                                    หรือขอขึ้นเงินเดือน ควรได้เท่าไหร่ดีนะ??</span>
                            </div>
                        </div>
                        <div className="right-control">

                            <div className="d-flex w-100">
                                <div className="form-bg-border"></div>

                                <div className="form-border-control d-flex flex-column">
                                    {
                                        inputValues ?

                                            <Form className="d-flex flex-column" id="form_submit" onSubmit={submit}>
                                                <h2 className="text-form-header">กรอกข้อมูลเพื่อเช็กเงินเดือน</h2>
                                                <Form.Label htmlFor="position" className="text-title">ประเภทงาน</Form.Label>

                                                <Form.Group>
                                                    <Typeahead
                                                        onChange={handleJobRoleChange}
                                                        id="valid-styles-example"
                                                        inputProps={{
                                                            className: 'input-control',
                                                        }}
                                                        options={autocompleteList}
                                                    />
                                                </Form.Group>
                                                {
                                                    (!jobRole && isSubmiting) && <Form.Text className="text-error" >
                                                        กรุณากรอกประเภทงาน
                                                    </Form.Text>
                                                }

                                                <div className="grid-50-control">
                                                    <div className="d-flex flex-column item-control">
                                                        <Form.Label htmlFor="experience" className="text-title">ประสบการณ์ทำงานทั้งหมด</Form.Label>
                                                        <Form.Select className="input-control" onChange={handleExpChange} >
                                                        <option value="">เลือกประสบการณ์</option>
                                                            {
                                                                inputValues &&
                                                                inputValues.experiences.map((item: Experience, index: number) => {
                                                                    return (
                                                                        <option value={item.value} key={index}>{item.name}</option>
                                                                    )
                                                                })
                                                            }
                                                        </Form.Select>
                                                        {
                                                            (!experience && isSubmiting) && <Form.Text className="text-error" >
                                                                เลือกประสบการณ์
                                                            </Form.Text>
                                                        }
                                                    </div>
                                                    <div className="d-flex flex-column">
                                                        <Form.Label htmlFor="jobType" className="text-title">ประเภทการจ้าง</Form.Label>
                                                        <Form.Select className="input-control" onChange={handleEmployTypeChange}>
                                                            <option value="">เลือกประเภทการจ้าง</option>
                                                            <option value="FULL_TIME">Full-Time</option>
                                                            <option value="CONTRACT">Contract</option>
                                                            <option value="FREELANCE">Freelance</option>
                                                        </Form.Select>
                                                        {
                                                            (!employType && isSubmiting) && <Form.Text className="text-error" >
                                                                เลือกประเภทกาารจ้าง
                                                            </Form.Text>
                                                        }
                                                    </div>
                                                </div>

                                                <Form.Label htmlFor="position" className="text-title">เงินเดือนปัจจุบัน</Form.Label>
                                                <Form.Control className="input-control"
                                                    type="number"
                                                    onChange={handleSalaryChange}
                                                    id="position"
                                                />
                                                {
                                                    (!currentSalary && isSubmiting) && <Form.Text className="text-error" >
                                                        กรุณากรอกเงินเดือน
                                                    </Form.Text>
                                                }

                                                <GoogleReCaptchaProvider reCaptchaKey={config.recaptcha_key} useRecaptchaNet={true}   >
                                                    <GoogleReCaptcha onVerify={onVerify} refreshReCaptcha={refreshReCaptcha}></GoogleReCaptcha>
                                                </GoogleReCaptchaProvider>
                                                <Form.Check className="input-control input-control-checkbox"
                                                    onChange={handleLookingJobs}
                                                    id="lookingJobs"
                                                    label="ฉันกำลังมองหางานใหม่อยู่" />
                                                <span className="text-consent">
                                                เมื่อกดปุ่ม 'เริ่มเช็คเงินเดือน' ถือว่าฉันได้อ่านและยอมรับ <a href={config.app.terms} className="blue">ข้อกําหนด/เงื่อนไขการใช้งาน</a>
                                                    และ <a href={config.app.privacy} className="blue">นโยบายความเป็นส่วนตัว</a>
                                                </span>

                                                <button className="button-submit" type="submit">เริ่มเช็คเงินเดือน</button>

                                                <span className="text-desc-gray">ระบบของเราจะทำการตรวจสอบเงินเดือนของคุณจากชื่อตำแหน่ง
                                                    งานปัจจุบันและประสบการณ์ที่คุณมีกับมาตรฐานเงินเดือนของ
                                                    วงการอุตสาหกรรมในประเทศไทยทั้งหมด</span>
                                            </Form>
                                            :

                                            <div className="d-flex my-auto">
                                                <div className="border-loading-control ">
                                                    <Lottie animationData={LoadingAnimation} loop={true} className="loading-json mx-auto my-auto" />
                                                    <span className="loading-text">Loading . . . </span>
                                                </div>

                                            </div>

                                    }
                                </div>
                            </div>


                        </div>
                    </div>
                </div>
                <div className="section-2-control">
                    <div className="container d-flex flex-column">
                        <span className="text-section-2-headline">
                            5 อันดับคน IT ที่นิยมเช็กเงินเดือนมากที่สุด
                        </span>
                        {
                            inputValues &&

                            <div className="grid-graph-control">
                                <div className="graph-item">

                                    <div className="graph-bg graph-bg-1 d-flex flex-column">
                                        <span className="text-position" >{inputValues?.top_careers[0]}</span>
                                    </div>
                                </div>
                                <div className="graph-item">
                                    <div className="graph-bg graph-bg-2 d-flex flex-column">
                                        <span className="text-position" >{inputValues?.top_careers[1]}</span>
                                    </div>
                                </div>
                                <div className="graph-item">
                                    <div className="graph-bg graph-bg-3 d-flex flex-column">
                                        <span className="text-position" >{inputValues?.top_careers[2]}</span>
                                    </div>
                                </div>
                                <div className="graph-item">
                                    <div className="graph-bg graph-bg-4 d-flex flex-column">
                                        <span className="text-position">{inputValues?.top_careers[3]}</span>
                                    </div>
                                </div>
                                <div className="graph-item">
                                    <div className="graph-bg graph-bg-5 d-flex flex-column">
                                        <span className="text-position" >{inputValues?.top_careers[4]}</span>
                                    </div>
                                </div>
                            </div>
                        }
                    </div>
                </div>
                <div className="section-3-control">
                    <div className="container d-flex flex-column">
                        <span className="text-section-3-headline">
                            เช็กเงินเดือนกับเรา คุณจะได้รับ……
                        </span>

                        <div className="section-3-grid-control">
                            <div className="grid-3-item-left">
                                <img src={IMG_LANDING_WHY_1} className="grid-3-item-img"></img>

                            </div>
                            <div className="grid-3-item-right">
                                <div className="text-3-grid-text-control d-flex flex-column">
                                    <span className="text-3-grid-headline">ข้อมูลเรทเงินเดือนมาตรฐานในสายงาน IT</span>
                                    <span className="text-3-grid-desc">เรามีฐานข้อมูลที่ทันสมัย ได้รับการอัปเดตอย่างสม่ำเสมอ
                                        เพื่อให้คุณได้ทราบข้อมูลเปรียบเทียบเงินเดือนกับคนที่ทำงานสาย IT
                                        ในท้องตลาดด้วยข้อมูลล่าสุด</span>
                                </div>
                            </div>
                        </div>
                        <div className="section-3-grid-control">

                            <div className="grid-3-item-right order-md-0 order-1">
                                <div className="text-3-grid-text-control-right d-flex flex-column">
                                    <span className="text-3-grid-headline">สรุปชัด เข้าใจง่าย</span>
                                    <span className="text-3-grid-desc">จากข้อมูลที่คุณระบุทั้งหมด เราจะสรุปข้อมูลด้วยกราฟฟิก
                                        ที่เข้าใจง่าย ชัดเจน ทำให้คุณทราบผลลัพธ์อย่างเข้าใจ
                                        และสามารถแชร์บนโซเชียลมีเดียของคุณ ได้อย่างสะดวกรวดเร็ว</span>
                                </div>
                            </div>
                            <div className="grid-3-item-left order-md-1 order-0">
                                <img src={IMG_LANDING_WHY_2} className="grid-3-item-img-right"></img>

                            </div>
                        </div>
                        <div className="section-3-grid-control-3">
                            <div className="grid-3-item-left">
                                <img src={IMG_LANDING_WHY_3} className="grid-3-item-img-3"></img>

                            </div>
                            <div className="grid-3-item-right">
                                <div className="text-3-grid-text-control-3 d-flex flex-column">
                                    <span className="text-3-grid-headline">ข้อมูลครบถ้วน แม่นยำ และปลอดภัย</span>
                                    <span className="text-3-grid-desc">เพียงระบุชื่อตำแหน่งงาน เงินเดือนปัจจุบันและประสบการณ์ที่คุณม
                                        ก็สามารถเช็กเงินเดือนสายงานไอทีได้แล้ว เพื่อความแม่นยำมากขึ้น
                                        คุณสามารถเลือกใส่ประเภทธุรกิจ สถานที่ทำงาน ประวัติการศึกษา
                                        หรือทักษะความสามารถของคุณเพิ่มเติมได้อีกด้วย โดยมั่นใจได้ว่า
                                        ข้อมูลทั้งหมดจะไม่มีการเปิดเผยข้อมูลของคุณอย่างแน่นอน</span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

            </div>
            {/* <FloatingAdsComponent></FloatingAdsComponent> */}
            <FooterComponent></FooterComponent>
        </>
    )
}

export default LandingPage;