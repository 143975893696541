import { useEffect, useState } from "react";
import { Modal } from "react-bootstrap";
import { FacebookShareButton } from "react-share";
import EmailIcon from "react-share/lib/EmailIcon";
import EmailShareButton from "react-share/lib/EmailShareButton";
import FacebookIcon from "react-share/lib/FacebookIcon";
import TwitterIcon from "react-share/lib/TwitterIcon";
import TwitterShareButton from "react-share/lib/TwitterShareButton";
import "./social-share.scss";

import IMG_SHARE_POPUP from "../assets/images/share-popup.png";
import LineShareButton from "react-share/lib/LineShareButton";
import LinkedinIcon from "react-share/lib/LinkedinIcon";
import LinkedinShareButton from "react-share/lib/LinkedinShareButton";
import LineIcon from "react-share/lib/LineIcon";

const SocialShareModal = ({ url, isShow, handleClose }: any) => {


    return (
        <>
            <Modal
                show={isShow}
                onHide={handleClose}
                backdrop="static"
                keyboard={false}
            >
                <Modal.Header closeButton>
                    <Modal.Title ><span className="social-share-header">แชร์</span></Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="social-share-control">
                        <img src={IMG_SHARE_POPUP} className="social-share-img"></img>
                        <div className="social-grid">
                            <FacebookShareButton
                                url={url}
                                quote={url}
                                hashtag="asdfasf"
                                className="img-icon-share"
                            >
                                <FacebookIcon round   className="img-icon-share" />
                            </FacebookShareButton>

                            <TwitterShareButton
                                url={url}
                                className="img-icon-share"
                            >
                                <TwitterIcon round   className="img-icon-share" />
                            </TwitterShareButton>

                            <LinkedinShareButton
                                url={url}
                                className="img-icon-share"
                            >
                                <LinkedinIcon round   className="img-icon-share" />
                            </LinkedinShareButton>

                            <LineShareButton
                                url={url}
                                className="img-icon-share"
                            >
                                <LineIcon round   className="img-icon-share" />
                            </LineShareButton>

                            <EmailShareButton url={url}    className="img-icon-share">
                                <EmailIcon round   className="img-icon-share"></EmailIcon>
                            </EmailShareButton>

                        </div>
                        <button className="close-button" onClick={handleClose}>ปิด</button>

                    </div>
                </Modal.Body>

            </Modal>
        </>
    )
}

export default SocialShareModal;