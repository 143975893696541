import { Timestamp } from "firebase/firestore";
import { useEffect, useState } from "react";
import { Form } from "react-bootstrap";
import AnalyticModel from "../../model/analytic.model";
import { FireStoreAnalyticModel } from "../../model/firestore-analytic.model";
import ApiServices from "../../services/api.services";
import "./report-resume.scss";


const ReportResumeComponent = () => {

    const [analyticModel, setAnalyticModel] = useState<FireStoreAnalyticModel[]>();
    const [filterChannel, setFilterChannel] = useState("");
    const [filterPage, setFilterPage] = useState("");
    const [filterStartDate, setFilterStartDate] = useState<Timestamp>();
    useEffect(() => {

        getAPIReport();
    }, [])

    const getAPIReport = () => {


        ApiServices().getReportResume().then((res) => {


            let analModel: FireStoreAnalyticModel[] = [];
            res.forEach((doc) => {
                // doc.data() is never undefined for query doc snapshots
                console.log(doc.data());

                const dataModel = doc.data() as FireStoreAnalyticModel;
                if (dataModel.created_date && dataModel.event_name && dataModel.event_value.name) {
                    analModel.push(dataModel);
                }

            });

            setAnalyticModel(analModel)
        })
    }

    const OnChangeChannel = (e: any) => {
        setFilterChannel(e.target.value);
        console.log(e.target.value);
    }
    const OnChangePageChannel = (e: any) => {
        setFilterPage(e.target.value);
        console.log(e.target.value);
    }

    const OnChangeStartDate = (e: any) => {
        const convertDate = new Date(e.target.value);
        console.log(convertDate);
        const startdate = Timestamp.fromDate(convertDate);
        console.log(startdate);
        setFilterStartDate(startdate)
    }

    return (

        <>
            <div className="report-comp">

                <div className="container">
                    <div className="d-flex report-form-header">
                        <Form.Select className="report-dropdown" onChange={(e) => OnChangeChannel(e)}>
                            <option value="">เลือก Channel</option>
                            <option value="/">/</option>
                            <option value="Techsauce">Techsauce</option>
                        </Form.Select>
                        <Form.Select className="report-dropdown" onChange={(e) => OnChangePageChannel(e)}>
                            <option value="">เลือก Page</option>
                            <option value="Landing Page">Landing Page</option>
                            <option value="Result Page">Result Page</option>
                        </Form.Select>
                        <Form.Control className="report-dropdown" type="date" name="dob" placeholder="Start Date"
                            onChange={(e) => OnChangeStartDate(e)} />
                    </div>
                </div>
                <div className="container">
                    <span>Total All :
                        {
                           ( analyticModel && filterStartDate) ?
                            analyticModel.filter((item: FireStoreAnalyticModel) =>
                                (filterStartDate && item.created_date >= filterStartDate)
                            ).length : analyticModel && analyticModel?.length
                        }

                    </span> <br></br>
                    <span>Total :
                        {
                            (analyticModel && filterStartDate) &&
                            
                                analyticModel.filter((item: FireStoreAnalyticModel) =>

                                    (item.event_value.channel == filterChannel) &&
                                    (item.event_value.name == filterPage) &&
                                    (item.created_date >= filterStartDate)
                                ).length
                        }

                    </span>

                    <div className="report-table-header">
                        <span className="report-item-table-header">Page</span>
                        <span className="report-item-table-header">Channel</span>
                        <span className="report-item-table-header">Job Role</span>
                        <span className="report-item-table-header">IP Address</span>
                        <span className="report-item-table-header">Date</span>
                    </div>

                    {
                        (analyticModel && filterStartDate) &&
                        analyticModel.filter((item: FireStoreAnalyticModel) =>

                            (item.event_value.channel == filterChannel) &&
                            (item.event_value.name == filterPage) &&
                            (item.created_date >= filterStartDate)
                        ).sort((a, b) => new Date(a.created_date.toDate()).getDate() - new Date(b.created_date.toDate()).getDate()).map((item: FireStoreAnalyticModel, index: number) => {

                            return (

                                item.created_date &&
                                <div className="report-table-header" key={index}>
                                    <span className="report-item-table">{item.event_value.name}</span>
                                    <span className="report-item-table">{item.event_value.channel}</span>
                                    <span className="report-item-table">{item.event_value.job_role}</span>
                                    <span className="report-item-table">{item.event_value.ip_address}</span>
                                    <span className="report-item-table">{item.created_date.toDate().toDateString()}</span>
                                </div>


                            )
                        })
                    }

                </div>
            </div>

        </>
    )
}

export default ReportResumeComponent;