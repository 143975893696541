
import "./footer.scss";
import IMG_SCOUTOUT_WHITE from "../../assets/images/scoutout-white.png";
import config from "../../config";
import UtilityService from "../../utils/utility";
import GAConfig from "../../ga.config";
import { useEffect, useState } from "react";
import { useLocation, useSearchParams } from "react-router-dom";

const FooterComponent = () => {
    const location = useLocation();
    const [searchParams, setSearchParams] = useSearchParams();
    const [isFooter, setIsFooter] = useState(true);
    useEffect(() => {
        try {
            if (location.pathname.includes("/techsauce")) {
                setIsFooter(false);
            } 
        } catch {

        }

    }, [])
    const clickLogo = () => {
        UtilityService().clickSendEvent(GAConfig.event.click, "Logo ScoutOut")
        window.open(config.app.about_so, "_blank");
    }

    return (
        <>
            {
                isFooter &&
                <div className="footer">

                    <div className="container footer-grid">
                        <img src={IMG_SCOUTOUT_WHITE} onClick={clickLogo} className="footer-so-img"></img>
                    </div>
                </div>
            }

        </>
    )
}

export default FooterComponent;