import HeaderComponent from "../../components/header/header";
import "./result.scss";
import IMG_TOP_RESULT_0 from "../../assets/images/result-top-img-0.png";
import IMG_TOP_RESULT_1 from "../../assets/images/result-top-img-1.png";
import IMG_TOP_RESULT_2 from "../../assets/images/result-top-img-2.png";
import INTERVIEW_AI_COVER from "../../assets/images/interview-ai-cover.jpg";
import IMG_QUOTE_BG from "../../assets/images/quote.png";
import IMG_QUOTE_UP_BG from "../../assets/images/quote-up.png";
import IMG_OP_TEXT from "../../assets/images/op-text.png";
import IMG_ONE_PROFILE from "../../assets/images/one-profile.png";
import RESUME_CHECKER from "../../assets/images/resume-checker-banner.jpg";
import PERSONALITY_TEST from "../../assets/images/personality-home-cover_th.png";
import { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import ResultModel from "../../model/result.model";
import PostInputModel from "../../model/post-input.model";
import IMG_RIGHT_BUTTON from "../../assets/images/right-button.png";
import IMG_SHARE from "../../assets/images/share.png";
import ApiServices from "../../services/api.services";
import JobRecommendModel, { Job } from "../../model/job-recommend.model";
import Carousel from "react-multi-carousel";
import UtilityService from "../../utils/utility";
import config from "../../config";
import FooterComponent from "../../components/footer/footer";
import SocialShareModal from "../../modal/social-share";
import BANNER_REFERRAL from "../../assets/images/banner-referral.png";
import GAConfig from "../../ga.config";
import ReactGA from "react-ga4";
import GAModel from "../../model/analytic.model";
import AnalyticModel from "../../model/analytic.model";
import { Button } from "react-bootstrap";
import RESUME_BUILDER from "../../assets/images/resume-builder.png"

const ResultPage = () => {
    const navigate = useNavigate();
    const location = useLocation();
    const [marginCurrent, setMarginCurrent] = useState(0);
    const [marginAverage, setMarginAverage] = useState(30);
    const [minSalary, setMinSalary] = useState(0);
    const [maxSalary, setMaxSalary] = useState(0);
    const [currentSalary, setCurrentSalary] = useState(15000);
    const [averageSalary, setAverageSalary] = useState(25000);
    const [textSpeakResult, setTextSpeakResult] = useState("เย้! ยินดีด้วยน้า");
    const [textSpeakResult2, setTextSpeakResult2] = useState("เงินเดือนของคุณเหมาะสมและเท่ากับคนส่วนใหญ่");
    const [statusResult, setStatusResult] = useState(0);
    const [resultObject, setResultObject] = useState<ResultModel>();
    const [inputObject, setInputObject] = useState<PostInputModel>();
    const [jobRecommend, setJobRecommend] = useState<JobRecommendModel>();
    const [showShare, setShowShare] = useState(false);
    const [urlShare, setUrlShare] = useState(false);
    const [ip, setIp] = useState("");

    const responsive = {
        desktop: {
            breakpoint: { max: 3000, min: 1024 },
            items: 4,
            slidesToSlide: 4 // optional, default to 1.
        },
        tablet: {
            breakpoint: { max: 1024, min: 501 },
            items: 2,
            slidesToSlide: 2 // optional, default to 1.
        },
        mobile: {
            breakpoint: { max: 500, min: 150 },
            items: 1,
            slidesToSlide: 1// optional, default to 1.
        }
    };

    useEffect(() => {
        try {
            if (location.state.result && location.state.inputValue) {
                let eventValue: AnalyticModel = {
                    name: "Result Page"
                }
                if (location.pathname.includes("/techsauce")) {
                    eventValue.channel = "Techsauce"
                } else {
                    eventValue.channel = "/"
                }

                const resultObject: ResultModel = location.state.result;
                const inputObject: PostInputModel = location.state.inputValue;
                setInputObject(inputObject)
                setResultObject(resultObject)
                setMinSalary(resultObject.min_salary)
                setMaxSalary(resultObject.max_salary)
                setCurrentSalary(inputObject.current_salary);
                setAverageSalary(resultObject.base_salary);
                eventValue.salary = inputObject.current_salary;
                eventValue.value = inputObject.role_value;
                UtilityService().clickSendEvent(GAConfig.event.load, resultObject.current_and_base_percent + "%")
                if (resultObject.current_and_base_percent === 0) {

                    setStatusResult(1);
                    setTextSpeakResult('ว้าว! ตรวจสอบแล้ว')
                    setTextSpeakResult2('เงินเดือนของคุณเหมาะสมและเท่ากับคนส่วนใหญ่')

                } else if (resultObject.current_and_base_percent < 0) {
                    setStatusResult(0);
                    setTextSpeakResult('โอ๋ๆ น้า จากผลลัพธ์ดูเหมือนว่า')
                    setTextSpeakResult2('คนส่วนใหญ่ได้เงินเดือนมากกว่าคุณ')
                } else {
                    setStatusResult(2);
                    setTextSpeakResult('เย้! ยินดีด้วยน้า')
                    setTextSpeakResult2('เงินเดือนของคุณสูงกว่าคนอื่นๆในท้องตลาด')
                }
                calculatePercentCurrentSalary(resultObject, inputObject)
                ApiServices().getIP().then((res) => {

                    if (res.IPv4) {
                        eventValue.ip_address = res.IPv4
                    }
                    ApiServices().createCustomAnalytic("page_view", eventValue, "salary-checker-tech").then((res) => {
                        console.log(res);
                    })
                })

                ApiServices().getRecommendJobs(inputObject.role_value).then((res: JobRecommendModel) => {
                    console.log(res);
                    setJobRecommend(res);
                })
            }
        } catch {
            navigate('/');
        }
    }, [])

    const clickJobRec = (item: Job) => {
        UtilityService().clickSendEvent(GAConfig.event.click, "Job - " + item.title + " - " + item.company.name)
        window.open(config.app.jobrec_click_url + item.id, "_blank");
    }
    const clickJobRecAll = (title: string) => {
        UtilityService().clickSendEvent(GAConfig.event.click, "recommend jobs all")
        window.open("https://plus.jobnow.work/jobs" + "?search=" + title + "&location=&offset=0", "_blank");
    }

    const clickShare = (url: any, title: any) => {
        UtilityService().clickSendEvent(GAConfig.event.click, "Share - " + title)
        setShowShare(true);
        setUrlShare(url);
    }

    const clickOneProfile = () => {
        UtilityService().clickSendEvent("Click Resume Builder from Result", "Open Resume Builder")
        window.open(config.app.resume_builder, '_blank');
    }

    const handleClose = () => {
        setShowShare(false);
    }

    const clickInterviewAI= () => {
        UtilityService().clickSendEvent(GAConfig.event.click, "App Interview AI")
        window.open('https://interview.jobhack.co?utm_source=SALARY_TECH', "_blank");
    }

    const clickJobHackJobs = () => {
        UtilityService().clickSendEvent(GAConfig.event.click, "App JobHack")
        window.open('https://app.jobhack.co?utm_source=SALARY_TECH', "_blank");
    }
    const clickResumeChecker = () => {
        UtilityService().clickSendEvent(GAConfig.event.click, "Resume Checker")
        window.open('https://resume-checker.jobhack.co?utm_source=SALARY_TECH', "_blank");
    }
    const clickPersonalityTest = () => {
        UtilityService().clickSendEvent(GAConfig.event.click, "Personality Test")
        window.open('https://quiz.scoutout.co?utm_source=SALARY_TECH', "_blank");
    }
    const clickCampaignReferral = () => {
        UtilityService().clickSendEvent(GAConfig.event.click, "Campaign Referral")
        window.open('https://jobhack.co/referral/scoutoutcampaign?utm_source=SALARY_TECH', "_blank");
    }
    const calculatePercentCurrentSalary = (resultObject: ResultModel, inputObject: PostInputModel) => {

        const perUnit = ((resultObject.max_salary - resultObject.min_salary) / 100);

        const unitAveragePercent = (resultObject.base_salary - resultObject.min_salary) / perUnit
        const unitPercent = unitAveragePercent + (inputObject.current_salary - resultObject.base_salary) / perUnit
        console.log(unitPercent);
        if (unitPercent == 0) {
            setMarginCurrent(1);
        } else if (unitPercent < 0) {
            setMarginCurrent(-1);
        }
        else if (unitPercent > 100) {
            setMarginCurrent(101);
        } else if (unitPercent == 100) {
            setMarginCurrent(99);
        }
        else {
            setMarginCurrent(unitPercent);
            setMarginAverage(unitAveragePercent);
        }
        console.log(marginCurrent);


    }
    return (
        <>
            <div className="result d-flex flex-column">
                <SocialShareModal url={urlShare} isShow={showShare} handleClose={handleClose}></SocialShareModal>
                <div className="result-section d-flex flex-column">
                    <HeaderComponent></HeaderComponent>
                    <div className="container box-result mx-auto">
                        <div className="top-box-result">
                            <img src={
                                statusResult === 0 ? IMG_TOP_RESULT_0 :
                                    statusResult === 1 ? IMG_TOP_RESULT_1 : IMG_TOP_RESULT_2
                            } className="img-top-box"></img>
                            <div className={
                                statusResult === 0 ? "text-top-result-control text-red" :
                                    statusResult === 1 ? "text-top-result-control text-yellow" : "text-top-result-control text-green"
                            }>
                                <span className="text-result-small">{textSpeakResult}</span>
                                <span className="text-result-headline">{textSpeakResult2}</span>

                            </div>
                            {
                                inputObject && <img src={IMG_SHARE} className="img-share" onClick={() => clickShare('https://salary.scoutout.co', inputObject?.role_value)}></img>
                            }

                        </div>
                        {
                            resultObject && <div className="text-detail-control">
                                <span className="text-detail-position">ตำแหน่งงานของคุณ : {inputObject?.role_value}</span>
                                <span className="text-detail-percent">เมื่อเทียบกับเรทเงินเดือนเฉลี่ยทั่วไปในสายงานเดียวกัน เงินเดือนของคุณ{
                                    resultObject!.current_and_base_percent > 0 ? 'มากกว่า ' + Math.abs(Number(resultObject?.current_and_base_percent)) + '%'
                                        : resultObject!.current_and_base_percent == 0 ? 'ได้มาตรฐาน' : 'น้อยกว่า ' + Math.abs(Number(resultObject?.current_and_base_percent)) + '%'
                                } </span>
                            </div>
                        }

                        <div className="d-flex">


                            <div className="bar-result-control">
                                {
                                    (statusResult === 0 && marginCurrent < 0) && <div className="bar-under-0-control">
                                        <div className="current-salary-control d-flex" style={{ 'marginLeft': '45%' }}>
                                            <div className="quote-salary-control">

                                                <img src={IMG_QUOTE_BG} className="quote-salary-img">


                                                </img>
                                                <div className="quote-salary-text-control">
                                                    <span className="quote-salary-text-1">เงินเดือนของคุณ</span>
                                                    <span className="quote-salary-text-2">{currentSalary.toLocaleString()} บาท</span>
                                                </div>
                                            </div>

                                        </div>

                                    </div>
                                }

                                <div className="bar-main" style={
                                    ((statusResult === 0 && marginCurrent >= 0) || (statusResult === 2 && marginCurrent <= 100)) ?
                                        { 'width': '90%' } : { 'width': '100%' }}>
                                    <span className="p10-text">P10*</span>
                                    <span className="p90-text">P90*</span>
                                    {
                                        ((statusResult === 0 && marginCurrent >= 0) || (statusResult === 2 && marginCurrent <= 100)) && <div className="current-salary-control d-flex" style={{ 'marginLeft': marginCurrent + '%' }}>
                                            <div className="quote-salary-control">

                                                <img src={IMG_QUOTE_BG} className="quote-salary-img">


                                                </img>
                                                <div className="quote-salary-text-control">
                                                    <span className="quote-salary-text-1">เงินเดือนของคุณ</span>
                                                    <span className="quote-salary-text-2">{currentSalary.toLocaleString()} บาท</span>
                                                </div>
                                            </div>

                                        </div>
                                    }

                                    <div className="average-salary-control d-flex" style={{ 'marginLeft': marginAverage + '%' }}>
                                        <div className="quote-salary-up-control">

                                            <img src={IMG_QUOTE_UP_BG} className="quote-salary-img">


                                            </img>
                                            <div className="quote-salary-text-control">
                                                <span className="quote-salary-text-1">เงินเดือนเฉลี่ย</span>
                                                <span className="quote-salary-text-2">{averageSalary.toLocaleString()} บาท</span>
                                            </div>
                                        </div>

                                    </div>
                                    <div className="text-min-control">

                                        <span className="text-min">{minSalary.toLocaleString()} บาท</span></div>
                                    <div className="text-max-control">

                                        <span className="text-max">{maxSalary.toLocaleString()} บาท</span></div>

                                </div>
                                {
                                    (statusResult === 2 && marginCurrent > 100) && <div className="bar-over-100-control">
                                        <div className="current-salary-control d-flex" style={{ 'marginLeft': '45%' }}>
                                            <div className="quote-salary-control">

                                                <img src={IMG_QUOTE_BG} className="quote-salary-img">


                                                </img>
                                                <div className="quote-salary-text-control">
                                                    <span className="quote-salary-text-1">เงินเดือนของคุณ</span>
                                                    <span className="quote-salary-text-2">{currentSalary.toLocaleString()} บาท</span>
                                                </div>
                                            </div>

                                        </div>

                                    </div>
                                }

                            </div>

                        </div>
                        <span className="text-description-why">* ค่า percentile เช่น P10 คำนวณมาจากการนำข้อมูลเงินเดือนทั้งหมดของตำแหน่งงานหนึ่งๆ มาเรียงจากน้อยไปมาก แล้วดึงข้อมูลในลำดับที่เทียบเท่าลำดับ
                            ที่ 10 จาก 100 เมื่อนับจากค่าที่น้อยที่สุด ขึ้นมาแสดง</span>

                            <div className="d-flex mx-auto flex-column">
                                <Button className="button-interview" onClick={clickInterviewAI}>ทดลองสัมภาษณ์งานตำแหน่งนี้ได้เลย ฟรี!</Button>
                                <span className="result-text-or">หรือ</span>
                                <div className="result-resume-builder-control">
                                    <span className="result-resume-builder-text">สร้าง Resume ฟรี ที่ 
                                    
                                    <img src={RESUME_BUILDER} className="result-resume-builder-img" onClick={clickOneProfile}></img></span>
                                </div>
                            </div>
                    </div>
                </div>
                <div className="container suggestion-control">
                    <h3 className="suggestion-text-headline">เคล็ดไม่ลับ!</h3>
                    <h3 className="suggestion-text-headline-sub">อัปเงินเดือนให้มากขึ้น!</h3>
                    <div className="one-profile-control">
                        <div className="one-profile-left-control order-md-0 order-1">
                            <span className="one-profile-text">
                                1. เช็ค Resume ปัจจุบันของคุณมีคุณภาพแล้วหรือยัง
                            </span>
                            <div className="one-profile-button-control" onClick={clickResumeChecker}>
                                <span>เช็ค Resume ของคุณที่นี่</span>
                                <img src={IMG_RIGHT_BUTTON} className="one-profile-button-img"></img>
                            </div>
                        </div>
                        <div className="one-profile-right-control order-md-1 order-0">
                            <img src={RESUME_CHECKER} className="resume-checker-banner" onClick={clickResumeChecker} ></img>
                        </div>

                    </div>
                    <div className="one-profile-control">
                        <div className="one-profile-left-control order-md-0 order-1">
                            <span className="one-profile-text">
                                2. รีบสร้าง Resume กับ
                            </span>
                            <img src={RESUME_BUILDER} className="one-profile-img" onClick={clickOneProfile}></img>
                           
                        </div>
                        <div className="one-profile-right-control order-md-1 order-0">
                            <img src="https://resume-builder.jobhack.co/share-cover.jpg" className="one-profile-right-img"  onClick={clickOneProfile}></img>
                        </div>
                    </div>

                    <div className="job-rec-control ">
                        <span className="job-rec-header ">3. เลือกสมัครงานใหม่ เพื่อเพิ่มเงินเดือนของคุณ</span>

                        <div className="job-rec-card-control">
                            {
                                jobRecommend &&
                                <Carousel
                                    responsive={responsive}
                                    dotListClass="custom-dot-list-style"
                                    itemClass="carousel-item-padding-40-px"
                                    containerClass="carousel-container"
                                    removeArrowOnDeviceType={[]}
                                >
                                    {

                                        jobRecommend?.jobs.map((item: Job, index: number) => {


                                            return (
                                                <div className="job-rec-card" key={index} onClick={() => clickJobRec(item)}>
                                                    <div className="title-and-logo-control">
                                                        <span className="title-and-logo-text">{

                                                            item.title.length > 25 ?

                                                                item.title.slice(0, 25) + "..." : item.title
                                                        }</span>
                                                        <img src={item.company.logoImageUrl} className="job-rec-logo"></img>
                                                    </div>
                                                    <span className="company-name">{item.company.name}</span>
                                                    <span className="location-name">{item.location}</span>
                                                    <span className="post-at">Posted {new Date(item.postedAt).toLocaleDateString()}</span>
                                                </div>
                                            )
                                        })

                                    }

                                    <div className="job-rec-card" onClick={() => clickJobRecAll(inputObject!.role_value)}>
                                        <span className="mx-auto my-auto view-all-text">View All</span>
                                    </div>
                                </Carousel>
                            }
                        </div>
                        <span className="job-rec-link" onClick={clickJobHackJobs}>หรือเลือกสมัครงานสาย Tech ทั้งหมดได้ที่นี่</span>
                    </div>
                </div>
                <div className="container suggestion-control">
                    <h3 className="suggestion-text-headline">สนุกกันต่อ</h3>
                    <h3 className="suggestion-text-headline-sub">กับกิจกรรมอื่นๆ ของทาง ScoutOuot!</h3>
                    <div className="one-profile-control">
                        <div className="one-profile-right-control">
                            <img src={INTERVIEW_AI_COVER} className="suggestion-img" onClick={clickInterviewAI}></img>
                        </div>
                        <div className="one-profile-left-control">
                            <span className="one-profile-text">
                                ทดลองสัมภาษณ์งานกับระบบ Generative AI ได้ฟรี!
                            </span>
                            <div className="one-profile-button-control" onClick={clickInterviewAI}>
                                <span>ทดลองสัมภาษณ์ ทันที คลิก</span>
                            </div>
                        </div>

                    </div>


                    <div className="one-profile-control">
                        <div className="one-profile-left-control order-md-0 order-1">
                            <span className="one-profile-text">
                                จริงๆแล้วคุณมีบุคลิกภาพ (16 personalities) แบบไหนนะ
                            </span>
                            <div className="one-profile-button-control" onClick={clickPersonalityTest}>
                                <span>เริ่มทำแบบทดสอบบุคลิกภาพเลย</span>
                                <img src={IMG_RIGHT_BUTTON} className="one-profile-button-img"></img>
                            </div>
                        </div>
                        <div className="one-profile-right-control order-md-1 order-0">
                            <img src={PERSONALITY_TEST} className="one-profile-right-img"></img>
                        </div>
                    </div>
                </div>

            </div>
            <FooterComponent></FooterComponent>
        </>
    )
}

export default ResultPage;